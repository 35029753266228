<template>
  <div id="result">
     <nav-bar class="detail-nav">
     <div class="back" slot="left">
          <img slot="left"  @click="backClick" src="~assets/images/common/back.png">
    </div>   
       
    <div class="title" slot="center">

      <div slot="center"  v-if="!title"> 绑定结果</div>
      <div slot="center"  v-else> {{title}}</div>
      
     <!-- <div   v-if="type!=2&&type!=3" slot="center"> 预约成功</div> -->
    </div>
 </nav-bar>


       <div class="content">
        <img src="~assets/images/common/success.png">
<!-- <div class="centerConter" > -->
      <div v-if="!title"  class="centerConter">
      <p> 恭喜您身份绑定成功,</p> 
      <p>请进入员工中心首页。</p> 
      </div>
      <div v-else  class="centerConter">
           <p> 恭喜您提交成功,</p> 
      <p>请耐心等待审核结果。</p> 
      </div>
     <!-- </div> -->

<!-- 
<div class="centerConter" v-if="type==1"><p>恭喜您申请保修服务成功,</p> 
      <p>请您耐心等待审核结果。</p>  </div>

<div class="centerConter" v-if="type==2"><p>恭喜您邀请成功,</p> 
      <p>请您耐心等待被邀请人回复</p>  </div>

<div class="centerConter" v-if="type==3"><p>恭喜您申请来访成功,</p> 
      <p>请您按时到访</p>  </div> -->   
     <van-button v-if="!title" class="commit" @click="commit">进入首页</van-button>
     <van-button v-else class="commit" @click="commit">确定</van-button>
         
       </div>
  </div>
</template>

<script>
import NavBar from 'common/navbar/NavBar'

import { Button } from 'vant';
export default {
    data () {
        return {
            server:'',
            title:''
        }
    },
    created () {
     this.server= this.$route.query.serve  
     this.title=this.$route.query.title  
    
    },
    components: {
      NavBar,
      [Button.name]:Button  
    },
methods: {
    backClick(){
        this.$router.back()
    },
    commit(){
        if(this.title){
        this.$router.back()

        }else{
        this.$router.push('/profile')

        }
        //  this.$router.back()
        
    }
}
}
</script>

<style scoped>
.centerConter{
   margin-top: 30px;
   margin-bottom: 50px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-content: start;
   align-items: center;
   /* font-weight: 300; */
   color: #333;
   font-size: 18px
}
.centerConter p{
font-weight: 300;
}
.centerConter :first-child{
    margin-bottom: 10px
}
.content{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.content img{
    width: 70px;
}
.back img{
    width: 18px;    
}
.commit{
    width: 60%;
    color: #fff;
     background-image: linear-gradient(to right, rgb(243, 70, 31) , rgb(243, 40, 71));

}
.back{
 /* background-color: aquamarine; */
   align-content: center;
   height: 55px;
   line-height: 55px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
</style>